import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

interface SliderInputProps {
  value?: any;
  onChange?: (value) => void;
}

const SliderInput: React.FC<SliderInputProps> = props => {
  const { value, onChange } = props;
  return (
    <div>
      <Slider
        min={1}
        max={5}
        step={0.1}
        value={value}
        trackStyle={{ height: "4px", background: "#6366F1" }}
        railStyle={{ height: "4px", background: "#DFE3E8" }}
        handleStyle={{
          background: "#fffff",
          border: "solid 2px #6366F1",
          borderRadius: "50%"
        }}
        onChange={onChange}
        className="w-52"
      />
    </div>
  );
};
export default SliderInput;
