import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Section from "../../../containers/Section";
import TextBlock from "../TextBlock";
import BidMultiplier from "./BidMultiplier";
import SliderWindow from "./SliderWindow";

interface OptimizeProps {
  title?: string;
  subTitle?: string;
  description?: string;
  image?: any;
}

const OptimizeSection: React.FC<OptimizeProps> = props => {
  const { title, subTitle, description, image } = props;

  // state for changing slider multiplier value
  const [bidMultiplier, setBidMultiplier] = React.useState<number>(2.3);

  return (
    <div className="bg-green-4 bg-opacity-10 rounded-tl-large rounded-br-large">
      <Section>
        <div className="flex flex-col lg:flex-row items-center justify-between pt-20 pb-36 lg:py-48 text-center md:text-left">
          <div className="w-full lg:w-1/2 lg:mr-24">
            <TextBlock
              label={subTitle}
              title={title}
              description={description}
            />
          </div>
          <div className="relative w-full lg:w-1/2 mt-12 lg:mt-0 left-1/2 -translate-x-1/2 lg:left-0 lg:translate-x-0">
            <GatsbyImage image={image} className="w-72 md:w-96 z-0" alt="" />

            <div className="absolute left-1/2 top-1/2 md:top-2/3 -translate-x-44 md:-translate-x-1/2 lg:-translate-x-1/4 z-10 scale-75 md:scale-100">
              <SliderWindow
                bidMultiplier={bidMultiplier}
                onChange={setBidMultiplier}
              />
            </div>

            <div className="absolute left-1/2 top-1/4 lg:top-1/3 lg:translate-x-10 -translate-x-1 z-10 scale-75 md:scale-100">
              <BidMultiplier bidMultiplier={bidMultiplier} />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default OptimizeSection;
