import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

interface QuoteProps {
  quoteDescription?: string;
  quotePersonName?: string;
  quotePersonTitle?: string;
  quotePersonImage?: any;
}

const Quote: React.FC<QuoteProps> = props => {
  const {
    quoteDescription,
    quotePersonName,
    quotePersonTitle,
    quotePersonImage
  } = props;

  return (
    <div className="border-l-4 border-gray-4-light mt-11 text-left">
      <div className="ml-8">
        <p className="text-lg text-gray-3-dark font-medium">
          {quoteDescription}
        </p>
        <div className="flex mt-5 md:mt-4">
          <GatsbyImage image={quotePersonImage} alt="" />
          <div className="text-base ml-4">
            <p className="text-gray-1 font-semibold">{quotePersonName}</p>
            <p className="text-gray-2-light font-medium">{quotePersonTitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Quote;
