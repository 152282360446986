import React from "react";
import Icon, { IconType } from "../../../Icon";
import {
  Link,
  Trans,
  useTranslation,
  useI18next
} from "gatsby-plugin-react-i18next";

interface BidMultiplierProps {
  bidMultiplier: number;
}

const BidMultiplier: React.FC<BidMultiplierProps> = props => {
  const { bidMultiplier } = props;
  const { language } = useI18next();
  const currentBidValue = 1.04;
  return (
    <div className="w-56 rounded-lg flex justify-between items-center bg-white p-6 text-3xl font-semibold border border-gray-4-light shadow-lg">
      <p className="text-gray-3-dark">
        {language != "en-US" ? `${currentBidValue}$` : `$${currentBidValue}`}
      </p>
      <Icon iconType={IconType.arrowRight} />
      <p className="text-indigo-1">
        {language != "en-US"
          ? `${Math.round(currentBidValue * bidMultiplier * 100) / 100}$`
          : `$${Math.round(currentBidValue * bidMultiplier * 100) / 100}`}
      </p>
    </div>
  );
};

export default BidMultiplier;
