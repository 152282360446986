import React from "react";
import Header, { HeaderVariant } from "../../Header";

interface TextBlockProps {
  label?: string;
  title: string;
  description: string;
  className?: string;
  children?: React.ReactNode;
}
const TextBlock: React.FC<TextBlockProps> = props => {
  const { label, title, description, children, className } = props;
  return (
    <div className={className}>
      {/* can add a variant for bold text in Paragraph comp ? */}
      <p className="text-2xl text-green-4 font-semibold mb-6">{label}</p>
      <Header variant={HeaderVariant.h2}>{title}</Header>
      <p className="text-2xl text-gray-3-dark font-semibold mb-6">
        {description}
      </p>
      <div>{children}</div>
    </div>
  );
};

export default TextBlock;
