import React from "react";
import SliderInput from "./SliderInput";
import Icon, { IconType } from "../../../Icon";
import {
  Link,
  Trans,
  useTranslation,
  useI18next
} from "gatsby-plugin-react-i18next";

interface SliderWindowProps {
  bidMultiplier: number;
  onChange: (number) => void;
}

const SliderWindow: React.FC<SliderWindowProps> = props => {
  const { onChange, bidMultiplier } = props;
  const { t } = useTranslation();
  const { language } = useI18next();
  return (
    <div className="bg-white w-full rounded-lg p-6 border border-gray-4-light shadow-lg flex flex-col text-center">
      <div className="flex text-base text-left font-semibold justify-between">
        <div className="w-1/2 mr-3">
          <p className=" text-gray-2-light">{t("Adjust By")}</p>
          <div className="w-44 h-9 rounded-lg border border-gray-3 flex justify-evenly items-center mt-2">
            <Icon iconType={IconType.optimizeBid} />
            <p className="text-gray-3-dark">{t("AI Powered")}</p>
            <Icon iconType={IconType.sort} />
          </div>
        </div>
        <div className="w-1/2">
          <p className="text-gray-2-light">{t("Duration")}</p>
          <div className="w-44 h-9 rounded-lg border border-gray-3 flex justify-evenly items-center mt-2">
            <Icon iconType={IconType.time} />
            <p className="text-gray-3-dark">{t("Always On")}</p>
            <Icon iconType={IconType.sort} />
          </div>
        </div>
      </div>
      <span className="text-base text-indigo-1 font-semibold mt-8 mb-3.5">{`${bidMultiplier}x`}</span>
      <SliderInput value={bidMultiplier} onChange={value => onChange(value)} />
    </div>
  );
};
export default SliderWindow;
