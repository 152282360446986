import React from "react";
import Quote from "./Quote";
import { graphql, useStaticQuery } from "gatsby";
import TextBlock from "../TextBlock";
import Section from "../../../containers/Section";
import { GatsbyImage } from "gatsby-plugin-image";

interface AnalyticsProps {
  title?: string;
  subTitle?: string;
  description?: string;
  image?: any;
  quoteDescription?: string;
  quotePersonName?: string;
  quotePersonTitle?: string;
  quotePersonImage?: any;
}

const AnalyticsSection: React.FC<AnalyticsProps> = props => {
  const {
    title,
    subTitle,
    description,
    image,
    quoteDescription,
    quotePersonName,
    quotePersonTitle,
    quotePersonImage
  } = props;

  return (
    <Section>
      <div className="flex flex-col-reverse lg:flex-row py-36 items-center">
        <div className="w-full lg:w-1/2 mt-14 lg:mt-0">
          <GatsbyImage image={image} alt="" />
        </div>
        <div className="w-full lg:w-1/2 lg:ml-24">
          <TextBlock
            label={subTitle}
            title={title}
            description={description}
            className="text-center md:text-left"
          >
            <Quote
              quoteDescription={quoteDescription}
              quotePersonName={quotePersonName}
              quotePersonTitle={quotePersonTitle}
              quotePersonImage={quotePersonImage}
            />
          </TextBlock>
        </div>
      </div>
    </Section>
  );
};

export default AnalyticsSection;
