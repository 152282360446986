import React from "react";
import TextBlock from "../TextBlock";
import { graphql, useStaticQuery } from "gatsby";
import Section from "../../../containers/Section";
import { GatsbyImage } from "gatsby-plugin-image";

interface GoalsProps {
  title?: string;
  subTitle?: string;
  description?: string;
  image?: any;
}

const GoalSection: React.FC<GoalsProps> = props => {
  const { title, subTitle, description, image } = props;

  return (
    <Section>
      <div className="flex flex-col-reverse lg:flex-row justify-between items-center pt-4 pb-20 lg:py-36">
        <div className="w-full lg:w-1/2 mt-14 md:mt-16 lg:mt-0">
          <GatsbyImage image={image} alt="" />
        </div>

        <div className="w-full lg:w-1/2 mt-20 lg:mt-0">
          <TextBlock
            label={subTitle}
            title={title}
            description={description}
            className="text-center md:text-left"
          />
        </div>
      </div>
    </Section>
  );
};

export default GoalSection;
