import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import BottomCta from "../components/BottomCta";
import AnalyticsSection from "../components/InstacartPage/AnalyticsSection";
import GoalSection from "../components/InstacartPage/GoalSection";
import HeroSection from "../components/InstacartPage/HeroSection";
import OptimizeSection from "../components/InstacartPage/OptimizeSection";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import { useSegment, trackView } from "../utils/analytics";
import { nav } from "../utils/navigation";
import { CTA } from "../constants/text";
import { URL, Intercom } from "../constants/destinations";
import { Name, Type, Location, Category, CTA_ID } from "../utils/analytics/constants";

interface InstacartPageProps {
  data: any;
}

const Instacart: React.FC<InstacartPageProps> = ({ data }) => {
  const { segment, helpers } = useSegment();

  const pageData = data?.allContentfulInstacartAdvertisingSoftware?.nodes[0];

  return (
    <Layout
      primaryButtonId={CTA_ID.getStartedNav.id}
    >
      <SEO
        title={pageData?.seoSettings?.metadataTitle}
        description={pageData?.seoSettings?.metadataDescription}
        image={pageData?.seoSettings?.openGraphImage?.url}
        robots={pageData?.seoSettings?.robots}
      />
      <HeroSection
        title={pageData?.heroMainTitle}
        gradientTitlePosition={pageData?.heroMainTitleGradientPosition}
        h1Title={pageData?.h1Title}
        description={pageData?.heroDescription?.heroDescription}
        image={pageData?.heroImage?.gatsbyImageData}
        cta={pageData?.heroCta}
        secondaryCta={pageData?.secondaryHeroCta}
      />
      <GoalSection
        title={pageData?.goalsTitle}
        subTitle={pageData?.goalsSubTitle}
        description={pageData?.goalsDescription?.goalsDescription}
        image={pageData?.goalsImage?.gatsbyImageData}
      />
      <OptimizeSection
        title={pageData?.optimizeMainTitle}
        subTitle={pageData?.optimizeSubTitle}
        description={pageData?.optimizeDescription?.optimizeDescription}
        image={pageData?.optimizeImage?.gatsbyImageData}
      />
      <AnalyticsSection
        title={pageData?.analyticsTitle}
        subTitle={pageData?.subTitle}
        description={pageData?.analyticsDescription?.analyticsDescription}
        image={pageData?.analyticsImage?.gatsbyImageData}
        quoteDescription={pageData?.analyticsQuote?.analyticsQuote}
        quotePersonName={pageData?.quotePersonName}
        quotePersonTitle={pageData?.quotePersonTitle}
        quotePersonImage={pageData?.quotePersonImage?.gatsbyImageData}
      />
      <BottomCta
        primaryButtonId={pageData?.bottomCta?.primaryButtonId}
        secondaryButtonId={pageData?.bottomCta?.secondaryButtonId}
        title={pageData?.bottomCta?.title}
        subtitle={pageData?.bottomCta?.subtitle}
        primaryButtonText={pageData?.bottomCta?.primaryButtonText}
        secondaryButtonText={pageData?.bottomCta?.secondaryButtonText}
        primaryButtonClassName={pageData?.bottomCta?.primaryButtonClassName}
        secondaryButtonClassName={pageData?.bottomCta?.secondaryButtonClassName}
        primaryButtonOnClick={() => {
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: pageData?.bottomCta?.primaryButtonText,
              page_url: window?.location.href,
              destination: pageData?.bottomCta?.primaryButtonRoute,
              location: Location.BottomCta
            })
            .then(() => nav(pageData?.bottomCta?.primaryButtonRoute));
        }}
        secondaryButtonOnClick={() => {
          trackView(Name.Cta, {
            category: Category.ButtonClick,
            type: Type.Button,
            text: pageData?.bottomCta?.secondaryButtonText,
            page_url: window?.location.href,
            destination: pageData?.bottomCta?.secondaryButtonRoute,
            location: Location.BottomCta
          });
          nav(pageData?.bottomCta?.secondaryButtonRoute);
        }}
      />
    </Layout>
  );
};

export default Instacart;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulInstacartAdvertisingSoftware(
      filter: { node_locale: { eq: $language } }
    ) {
      nodes {
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            gatsbyImageData
            url
          }
          robots
        }
        h1Title
        heroMainTitle
        heroMainTitleGradientPosition
        heroDescription {
          heroDescription
        }
        heroImage {
          gatsbyImageData
          url
        }
        heroInputPlaceholder
        heroCta {
          text
          route
        }
        secondaryHeroCta {
          route
          text
        }
        goalsSubTitle
        goalsTitle
        goalsDescription {
          goalsDescription
        }
        goalsImage {
          gatsbyImageData
          url
        }
        optimizeSubTitle
        optimizeMainTitle
        optimizeDescription {
          optimizeDescription
        }
        optimizeImage {
          gatsbyImageData
          url
        }
        analyticsSubtitle
        analyticsTitle
        analyticsDescription {
          analyticsDescription
        }
        analyticsImage {
          gatsbyImageData
          url
        }
        analyticsQuote {
          analyticsQuote
        }
        quotePersonName
        quotePersonTitle
        quotePersonImage {
          gatsbyImageData
          url
        }
        bottomCta {
          title
          subtitle
          primaryButtonId
          primaryButtonText
          secondaryButtonId
          secondaryButtonText
          primaryButtonRoute
          secondaryButtonRoute
          primaryButtonClassName
          secondaryButtonClassName
        }
      }
    }
  }
`;
