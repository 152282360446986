import React from "react";
import Section from "../../../containers/Section";
import Badge from "../../Badge";
import Brands, { BrandObject } from "../../Brands";
import FourSigmatic from "../../Brands/BrandSvgs/foursigmatic.svg";
import HavensKitchen from "../../Brands/BrandSvgs/havenskitchen.svg";
// import brand logos
import Mikes from "../../Brands/BrandSvgs/mikes.svg";
import Navitas from "../../Brands/BrandSvgs/navitas.svg";
import NoCow from "../../Brands/BrandSvgs/nocow.svg";
import Popchips from "../../Brands/BrandSvgs/popchips.svg";
import WanderingBear from "../../Brands/BrandSvgs/wanderingbear.svg";
import { IconType } from "../../Icon";
import NavigationBarSpacer from "../../NavigationBarSpacer";
import Paragraph from "../../Paragraph";
import { GatsbyImage } from "gatsby-plugin-image";
import GradientTitle from "../../GradientTitle";
import { HeaderVariant } from "../../Header";
import HeroCtas from "../../HeroCtas";

interface HeroSectionProps {
  h1Title?: string;
  title?: string;
  gradientTitlePosition?: number;
  description?: string;
  image?: any;
  cta?: any;
  secondaryCta?: any;
}

const HeroSection: React.FC<HeroSectionProps> = props => {
  const {
    title,
    gradientTitlePosition,
    h1Title,
    description,
    image,
    cta,
    secondaryCta
  } = props;

  // array of brand logos
  const brandsList: Array<BrandObject> = [
    { src: <Mikes />, filter: true },
    { src: <FourSigmatic />, filter: true },
    { src: <Navitas />, filter: true },
    { src: <Popchips />, filter: true },
    { src: <NoCow />, filter: true },
    { src: <HavensKitchen />, filter: true },
    { src: <WanderingBear />, filter: true }
  ];
  return (
    // background color ->  which shade of green?
    <div className="pt-20 bg-gradient-to-b from-green-3 to-white">
      <NavigationBarSpacer />
      <Section>
        <div className="flex justify-center md:justify-start">
          <Badge
            className="mb-12"
            iconType={IconType.instacart}
            title={h1Title}
            label={true}
          />
        </div>
        <div className="flex justify-between">
          {/* text section */}
          <div className="w-full lg:w-1/2 text-center md:text-left">
            <GradientTitle
              title={title}
              gradientPosition={gradientTitlePosition}
              variant={HeaderVariant.h2}
              color="green"
              lineBreak={false}
              className="md:text-6xl"
            />
            <Paragraph className="text-gray-3-dark mt-8">
              {description}
            </Paragraph>
            <HeroCtas cta={cta} secondaryCta={secondaryCta} />
          </div>

          {/* image section - only visible on large screens */}
          <div className="invisible lg:visible lg:w-1/2">
            <div className="ml-6 relative">
              <GatsbyImage image={image} alt="" />
            </div>
          </div>
        </div>
        <div className="mt-20 lg:mt-35">
          <Brands brandsList={brandsList} />
        </div>
      </Section>
    </div>
  );
};

export default HeroSection;
